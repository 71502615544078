import React from 'react';
import constants from '../../constants';
import {translate as _} from 'simple-dict-translator';
import Button from '../Buttons/Button';

const Header = ({
    className,
    showClose,
    onClose = () => {},
    showMenu,
    onMenu = () => {},
    onLanguageChange = () => {},
    currentLanguage = constants.LANGUAGE_DEFAULT,
    ...props
}) => {
    const classNames = ['header'];

    if (className) {
        classNames.push(className);
    }

    return (
        <div className={classNames.join(' ')}>
            <div className="header__languages">
                <Button
                    className={[
                        'header__languages__language heading heading--medium heading--normal',
                        currentLanguage === constants.LANGUAGE_SWEDISH ? 'header__languages__language--active' : 'header__languages__language--inactive'
                    ].join(' ')}
                    title={_('language_swe_shortname')}
                    onClick={() => {
                        onLanguageChange(constants.LANGUAGE_SWEDISH);
                    }}
                />
                <span className={'heading heading--medium heading--normal'}>/</span>
                <Button
                    className={[
                        'header__languages__language heading heading--medium heading--normal',
                        currentLanguage === constants.LANGUAGE_ENGLISH ? 'header__languages__language--active' : 'header__languages__language--inactive'
                    ].join(' ')}
                    title={_('language_en_shortname')}
                    onClick={() => {
                        onLanguageChange(constants.LANGUAGE_ENGLISH);
                    }}
                />
            </div>
            <div className="header__actions">
                {showClose && (
                    <Button className="header__actions__action header__actions__action--close" onClick={onClose} icon={constants.ICON_CLOSE} title={_('close')} />
                )}
                {showMenu && (
                    <Button className="header__actions__action header__actions__action--menu" onClick={onMenu} icon={constants.ICON_MENU} title={_('menu')} />
                )}
            </div>
        </div>
    );
};

export default Header;