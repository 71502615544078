import ContentService from './ContentService';

class PageService extends ContentService {
    getPageBySlug(slug) {
        const match = this.getState().pages.find(page => (page.slug === slug));

        return match;
    }

    getPageNavigation() {
        const pages = this.getState().pages.map(page => {
            return {
                slug: page.slug,
                title: page.menu_label
            };
        });

        return pages;
    }
}

export default PageService;
