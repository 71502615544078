const constants = {
    PUBLIC_BASE_URL: process.env.PUBLIC_URL,

    PAGE_THEME_PRIMARY: 'page--primary',
    PAGE_THEME_SECONDARY: 'page--secondary',

    NOT_FOUND_404_PATH: '/404-not-found',

    LANGUAGE_SWEDISH: 'sv_se',
    LANGUAGE_ENGLISH: 'en_us',

    ATTACHMENT_TYPE_IMAGE: 'image',
    ATTACHMENT_TYPE_YOUTUBE: 'youtube',

    LOCALE_CHANGE_EVENT: 'LOCALE_CHANGE_EVENT',
    IMAGE_MAP_SHAPE_POLYGON: 'poly',
    ICON_MENU: 'icon-menu',
    ICON_CLOSE: 'icon-close'
};

constants.LANGUAGES = [
    constants.LANGUAGE_ENGLISH,
    constants.LANGUAGE_SWEDISH
]; 

constants.ICONS = [
    constants.ICON_MENU,
    constants.ICON_CLOSE
]; 

constants.LANGUAGE_DEFAULT = constants.LANGUAGE_SWEDISH;
constants.LANGUAGE_FALLBACK = constants.LANGUAGE_SWEDISH;

export default constants;
