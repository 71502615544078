import React, {useRef, useEffect, useState} from 'react';

function coordsToString(coords, scaleWidth, scaleHeight) {
    return coords.map((pos, i) => {
        let scaledPos = pos;

        if (i % 2) {
            scaledPos = pos * scaleHeight;
        } else {
            scaledPos = pos * scaleWidth;
        }
        
        return Math.round(scaledPos);
    }).join(',');
}

const ImageMap = ({
    id,             // A unique id for the image-map
    className,      // Optional className
    imageUrl,       // The url of the background image
    imageAlt = '',  // Alt for the background image
    areas = [],     // Array of area maps, [{ key: '', alt: '', shape: 'poly', coords: [[0,0],[0,2],[2,2][2,0]] }]

    ...props
}) => {
    const mapImage = useRef(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [naturalDimensions, setNaturalDimensions] = useState({height: 1, width: 1});
    const [actualDimensions, setActualDimensions] = useState({height: 1, width: 1});

    const classNames = ['image-map'];
    const mapName = `image-map-${id}`;

    const heightScaleRatio = actualDimensions.height / naturalDimensions.height;
    const widthScaleRatio = actualDimensions.width / naturalDimensions.width;

    if (className) {
        classNames.push(className);
    }

    const updateDimensions = () => {
        if (mapImage.current) {
            setNaturalDimensions({
                height:  mapImage.current.naturalHeight,
                width: mapImage.current.naturalWidth
            });
            setActualDimensions({
                height: mapImage.current.offsetHeight,
                width: mapImage.current.offsetWidth
            });
        }
    };

    useEffect(() => {
        updateDimensions();
    }, [mapImage, imageLoaded]);

    useEffect(() => {
        const onResize = () => {
            updateDimensions();
        };

        window.addEventListener('resize', onResize);
    
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);


    return (
        <div className={classNames.join(' ')} {...props}>
            <img
                ref={mapImage}
                useMap={`#${mapName}`}
                src={imageUrl}
                className={'image-map__image'}
                alt={''} 
                hidefocus="true"
                onLoad={() => {
                    setImageLoaded(true);
                }}
            />
            <map name={mapName}>
                {areas.map(area => {
                    return (
                        <area
                            key={area.key}
                            alt={''}
                            title={''}
                            shape={area.shape}
                            coords={coordsToString(area.coords, widthScaleRatio, heightScaleRatio)}
                            href="#"
                            onClick={(ev) => {
                                ev.preventDefault();
                                area.onClick();
                            }}
                        ></area>
                    );
                })}
            </map>

        </div>
    );
};

export default ImageMap;