import React from 'react';
import { withRouter } from 'react-router-dom';

import Page from './Page';

const NotFound = ({match: { params }, history}) => {
    return (
        <Page>
            404 - Page not found
        </Page>
    );
};

export default withRouter(NotFound);
