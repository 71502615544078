import React from 'react';
import { withRouter } from 'react-router-dom';
import withLocale from '../../Hoc/withLocale';

import constants from '../../constants';
import HeaderView from '../../Components/Layout/Header';

const Header = ({history, showClose, showMenu, changeLocale = () => {}, currentLocale = constants.LANGUAGE_DEFAULT}) => {
    return (
        <HeaderView
            showMenu={showMenu}
            onMenu={() => {
                history.push('/menu');
            }}
            showClose={showClose}
            onClose={() => {
                const canGoBack = (history.length > 1);
                if (canGoBack) {
                    history.goBack();
                } else {
                    history.push('/');
                }
            }}
            currentLanguage={currentLocale}
            onLanguageChange={(locale) => {
                changeLocale(locale);
            }}
        />
    );
};

export default withLocale(withRouter(Header));
