import React from 'react';

const LargeTitle = ({
    title = '',
    subtitle = '',
    className = '',
    ...props
}) => {
    const classNames = ['large-title'];

    if (className) {
        classNames.push(className);
    }

    return (
        <div className={classNames.join(' ')} {...props}>
            <h1 className="large-title__title heading heading--xlarge">{title}</h1>
            {!!subtitle && (
                <p className="large-title__subtitle text text--small">{subtitle}</p>
            )}
        </div>
    );
}

export default LargeTitle;