import ContentService from './ContentService';

class ArtWorkService extends ContentService {
    getPageBySlug(slug) {
        const match = this.getState().artworks.find(artwork => (artwork.slug === slug));

        return match;
    }

    getArtworks() {
        const artworks = this.getState().artworks.map(artwork => {
            return {
                slug: artwork.slug,
                title: artwork.slug,
                coords: artwork.coords
            };
        });

        return artworks;
    }
}

export default ArtWorkService;
