import {translator} from 'simple-dict-translator';

import sv_se from '../../state/sv_se';
import en_us from '../../state/en_us';
import constants from '../constants';

class ContentService {
    getState() {
        const currentLocale = translator.getCurrentLocale();
        
        if (currentLocale === constants.LANGUAGE_SWEDISH) {
            return sv_se;
        }
        if (currentLocale === constants.LANGUAGE_ENGLISH) {
            return en_us;
        }

    }
}

export default ContentService;
