import React from 'react';
import { withRouter } from 'react-router-dom';

import Header from './Navigation/Header';
import PageLayout from '../Components/Layout/Page';

const Page = ({
    children,
    showMenu = true,
    showClose = false,
    ...props
}) => {
    const header = (
        <Header showClose={showClose} showMenu={showMenu} />
    );

    return (
        <PageLayout header={header} {...props}>
            {children}
        </PageLayout>
    );
};

export default withRouter(Page);
