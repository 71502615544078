import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import Page from './Page';
import Html from '../Components/Html';
import PageService from '../Services/PageService';
import constants from '../constants';
import Attachment from '../Components/Attachment/Attachment';

const Article = ({match: { params }, history}) => {
    const pageService = new PageService();
    const article = pageService.getPageBySlug(params.slug);

    if (!article) {
        return (<Redirect to={constants.NOT_FOUND_404_PATH} />);
    }

    return (
        <Page
            className={'page-article'}
            theme={constants.PAGE_THEME_SECONDARY}
            showClose={true}
            showMenu={false}
        >
            <h1 className="heading heading--large">{article.title}</h1>

            <Html>{article.content}</Html>

            {!!article.attachments && !!article.attachments.length && (
                <div className={'page-article__attachments'}>
                    {article.attachments.map((attachment, i) => (
                        <Attachment key={i} attachment={attachment} />
                    ))}
                </div>
            )}
        </Page>
    );
};

export default withRouter(Article);
