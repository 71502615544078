import React, {Fragment} from 'react';
import {
    MemoryRouter as Router,
    Route
} from 'react-router-dom';

import TopAnimatedSwitch from './Routing/TopAnimatedSwitch';
import FadeAnimatedSwitch from './Routing/FadeAnimatedSwitch';
import ScrollToTop from './Routing/ScrollToTop';
import constants from './constants';
import Intro from './Containers/Intro';
import Artworks from './Containers/Artworks';
import Menu from './Containers/Menu';
import Artwork from './Containers/Artwork';
import Article from './Containers/Article';
import NotFound from './Containers/NotFound';
import withLocale from './Hoc/withLocale';

const App = (props) => {
    console.log(props);
    return (
        <div lang={props.currentLocale.split('_').join('-')}>
            <Router>
                <Fragment>
                    <ScrollToTop />
                    <TopAnimatedSwitch>
                        <Route exact path="/" component={Intro} />
                        <Route path="/artwork">
                            <FadeAnimatedSwitch>
                                <Route exact path="/artwork/start" component={Artworks} />
                                <Route 
                                    exact
                                    path={'/artwork/:slug'}
                                    component={Artwork}
                                />
                            </FadeAnimatedSwitch>
                        </Route>
                        <Route exact path="/menu" component={Menu} />
                        <Route 
                            path={'/page/:slug'}
                            component={Article}
                        />
                        <Route exact path={constants.NOT_FOUND_404_PATH} component={NotFound} />
                        <Route component={NotFound} />
                    </TopAnimatedSwitch>
                </Fragment>
            </Router>
        </div>
    );
}

export default withLocale(App);
