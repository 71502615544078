import React from 'react';

const Html = ({className, children, ...props}) => {
    const classNames = ['html'];
    
    if (className) {
        classNames.push(className);
    }

    return (
        <div className={classNames.join(' ')} {...props}>
            <div className={'html__content'} dangerouslySetInnerHTML={{ __html: children }} />
        </div>
    );
};

export default Html;