import React from 'react';

const Image = ({className, url = '', alt = '', ...props}) => {
    const classNames = ['attachment-image'];
    
    if (className) {
        classNames.push(className);
    }

    return (
        <div className={classNames.join(' ')}>
            <img className={'attachment-image__img'} src={url} alt={''} {...props} />
        </div>
    );
};

export default Image;
