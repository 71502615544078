import React from 'react';
import constants from '../../constants';
import {translate as _} from 'simple-dict-translator';

const Page = ({children, header, showFooter = true, theme = constants.PAGE_THEME_PRIMARY, className, ...props}) => {
    const classNames = ['page'];
    if (theme) {
        classNames.push(theme);
    }
    if (className) {
        classNames.push(className);
    }

    return (
        <div className={classNames.join(' ')}>
            <header className="page__header">
                {header}
            </header>
            <div className="page__content">
                {children}
                {!!showFooter && (
                    <footer className="page__content__footer"></footer>
                )}
            </div>
        </div>
    );
};

export default Page;