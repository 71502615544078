import React from 'react';
import { withRouter } from 'react-router-dom';

import backgroundImage from '../../assets/map.png';
import constants from '../constants';
import ArtworkService from '../Services/ArtworkService';
import Page from './Page';
import LargeTitle from '../Components/LargeTitle';
import ImageMap from '../Components/ImageMap';
import Banner from '../Components/Banner';
import tokenGenerator from '../Helpers/tokenGenerator';
import {translate as _} from 'simple-dict-translator';

const Start = ({match: { params }, history}) => {
    const artworkService = new ArtworkService();

    return (
        <Page className={'page-artworks'}>
            <LargeTitle title={_('main_title')} subtitle={_('main_subtitle')} />
            <ImageMap
                id={tokenGenerator()}
                imageUrl={backgroundImage}
                areas={artworkService.getArtworks().map((artwork) => {
                    return {
                        ...artwork,
                        key: artwork.slug,
                        alt: artwork.title,
                        shape: constants.IMAGE_MAP_SHAPE_POLYGON,
                        onClick: () => {
                            history.push('/artwork/' + artwork.slug);
                        }
                    };
                })}
            />
            <Banner
                message={_('banner_message')}
            />
        </Page>
    );
};

export default withRouter(Start);
