import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import Page from './Page';
import PageService from '../Services/PageService';

import constants from '../constants';

import MenuView from '../Components/Menu/Menu';
import MenuItem from '../Components/Menu/MenuItem';

const Menu = ({match: { params }, history}) => {
    const pageService = new PageService();
    const menuItems = pageService.getPageNavigation();

    return (
        <Page
            className={'page-menu'}
            showClose={true}
            showMenu={false}
            theme={constants.PAGE_THEME_SECONDARY}
            showFooter={false}
        >
            <MenuView>
                {menuItems.map(item => (
                    <MenuItem key={item.slug}>
                        <Link to={'/page/' + item.slug}>{item.title}</Link>
                    </MenuItem>
                ))}
            </MenuView>
        </Page>
    );
};

export default withRouter(Menu);
