import tokenGenerator from '../Helpers/tokenGenerator';

class PubSub {
    constructor() {
        this.topics = [];
    }


    subscribe(topic, callback) {
        if (!this.topics[topic]) {
            this.topics[topic] = [];
        }

        const token = tokenGenerator();

        this.topics[topic].push({
            token: token,
            func: callback
        });

        return token;
    }

    publish(topic, args) {
        if (!this.topics[topic]) {
            return false;
        }

        setTimeout(() => {
            const subscribers = this.topics[topic];
            let length = subscribers ? subscribers.length : 0;

            while (length--) {
                subscribers[length].func(topic, args);
            }
        },0);

        return true;
    }

    unsubscribe(token) {
        for (const m in this.topics) {
            if (this.topics[m]) {
                for (let i = 0, j = this.topics[m].length; i < j; i++) {
                    if (this.topics[m][i].token === token) {
                        this.topics[m].splice(i, 1);
                        return token;
                    }
                }
            }
        }

        return false;
    }
}

export default PubSub;
