import React from 'react';
import {translate as _} from 'simple-dict-translator';
import constants from '../../constants';
import Image from './AttachmentImage';
import Youtube from './AttachmentYoutube';
import Html from '../Html';

const Attachment = ({className, attachment = {}, ...props}) => {
    const classNames = ['attachment'];
    const {
        caption,
        references = [],
        type
    } = attachment;

    if (className) {
        classNames.push(className);
    }

    let content = '';

    switch (type) {
        case constants.ATTACHMENT_TYPE_IMAGE:
            content = (
                <Image className={'attachment__media'} url={attachment.url} alt={''} />
            );
            break;
        case constants.ATTACHMENT_TYPE_YOUTUBE:
            content = (
                <Youtube className={'attachment__media'} youtubeId={attachment.youtubeId} alt={''} />
            );
            break;
        default:
            content = '';
            break;
    }

    return (
        <div
            className={classNames.join(' ')}
            {...props}
        >
            {content}
            {!!caption && (
                <Html className={'attachment__caption'}>{caption}</Html>
            )}
            {!!references.length && (
                <div  className={'attachment__references text text--small'}>
                    <span className={'attachment__references__label'}>{_('references')} </span>
                    <ul className={'attachment__references__items'}>
                        {references.map(reference => {
                            return (
                                <li key={reference.name} className={'attachment__references__items__item'}>
                                    {!!reference.url && (<a href={reference.url}>{reference.name}</a>)}
                                    {!reference.url && (<span>{reference.name}</span>)}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Attachment;
