
import {translator} from 'simple-dict-translator';
import PubSub from '../Abstract/PubSub';
import constants from '../constants';

class LocaleService extends PubSub {
    addTranslation(locale, translations) {
        translator.addTranslation(locale, translations);
    }

    setFallbackLocale(locale) {
        translator.setFallbackLocale(locale);
    }

    getCurrentLocale() {
        return translator.getCurrentLocale();
    }

    setCurrentLocale(locale) {
        translator.setCurrentLocale(locale);
        
        this.publish(
            constants.LOCALE_CHANGE_EVENT,
            {locale}
        );
    }
}

const localeServiceInstance = new LocaleService();

export default localeServiceInstance;
