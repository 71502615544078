import React from 'react';

const Banner = ({
    className,
    message,
    onClick = () => {},
    ...props
}) => {
    const classNames = ['banner'];
    if (className) {
        classNames.push(className);
    }
    return (
        <div
            className={classNames.join(' ')}
            onClick={() => {
                onClick();
            }}
            {...props}
        >
            <span className={'banner__message heading heading--normal heading--small'}>{message}</span>
        </div>
    );

};

export default Banner;