import React from 'react';

const Menu = ({children, className, ...props}) => {
    const classNames = ['menu'];

    if (className) {
        classNames.push(className);
    }

    return (
        <ul className={classNames.join(' ')} {...props}>
            {children}
        </ul>
    );
};

export default Menu;