import React, {useState, useEffect} from 'react';
import constants from '../constants';
import localeService from '../Services/LocaleService';

// This function takes a component...
function withLocale(WrappedComponent) {
    return (props) => {
        const [currentLocale, setCurrentLocale] = useState(localeService.getCurrentLocale());

        useEffect(() => {
            const listenerToken = localeService.subscribe(
                constants.LOCALE_CHANGE_EVENT, 
                (event, args) => {
                    setCurrentLocale(args.locale);
                }
            );

            return () => {
                localeService.unsubscribe(listenerToken);
            };
        }, []);

        return (
            <WrappedComponent
                currentLocale={currentLocale}
                changeLocale={(newLocale) => {
                    localeService.setCurrentLocale(newLocale);
                }}
                {...props}
            />
        );
    };
}

export default withLocale;