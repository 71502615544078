import React from 'react';

const MenuItem = ({children, className, ...props}) => {
    const classNames = ['menu-item heading heading--medium heading--normal'];

    if (className) {
        classNames.push(className);
    }

    return (
        <li className={classNames.join(' ')} {...props}>
            {children}
        </li>
    );
};

export default MenuItem;
