const sv_se = {
    'language_swe_shortname': 'SVE',
    'language_en_shortname': 'ENG',
    'menu': 'Meny',
    'to_the_artwork': 'Till verket',
    'main_title': 'MATRIX',
    'main_subtitle': 'Av Ea ten Kate, Jonathan Josefsson och Valeria Montti Colque för Världskulturmuseet',
    'intro_title': 'MATRIX',
    'intro_subtitle': 'Av Ea ten Kate, Jonathan Josefsson och Valeria Montti Colque för Världskulturmuseet',
    'intro_content': 'Välkommen till Matrix! Ett konstnärligt samarbetsprojekt där konstnärerna tillsammans utgått ifrån museets samlingar och teman. Här kan du upptäcka konstverket och läsa mer om berättelserna bakom. Välkommen till Matrix, ett konstverk på marken!',
    'banner_message': 'Klicka på figurerna för att få veta mer',
    'artists_and': ' och ',
    'by_artists': 'Konstnärer:',
    'by_artist': 'Konstnär:',
    'about_figure': 'Om figuren',
    'about_objects': 'Om föremålen',
    'references': 'Referens-id:',
    'play_video': 'Spela upp video',
    'play': 'Spela',
    'right_arrow': 'Pil mot höger',
};

export default sv_se;
