import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import { translate as _ } from 'simple-dict-translator';

import Page from './Page';
import LargeTitle from '../Components/LargeTitle';
import ArtWorkService from '../Services/ArtworkService';
import constants from '../constants';
import Artists from '../Components/Artwork/Artists';
import Html from '../Components/Html';
import Attachment from '../Components/Attachment/Attachment';

const Artwork = ({match: { params }, history}) => {
    const artworkService = new ArtWorkService();
    const artwork = artworkService.getPageBySlug(params.slug);
    const hasAttachments = artwork.attachments && artwork.attachments.length !== 0;

    if (!artwork) {
        return (<Redirect to={constants.NOT_FOUND_404_PATH} />);
    }

    return (
        <Page
            className={'page-artwork'}
            theme={constants.PAGE_THEME_SECONDARY}
            showClose={true}
            showMenu={false}
        >
            <LargeTitle title={_('main_title')} />

            {!!artwork.image.url && (
                <img className={'page-artwork__image'} src={constants.PUBLIC_BASE_URL + artwork.image.url} alt={artwork.image.alt} />
            )}
            {!!artwork.artists && (
                <Artists className={'page-artwork__artists'} artists={artwork.artists} />
            )}

            <div className={'page-artwork__content'}>
                <Html>{artwork.content}</Html>
            </div>

            {!!artwork.about_object && (
                <Html className={'page-artwork__about-object'}>{artwork.about_object}</Html>
            )}
            <div className={'page-artwork__attachments'}>
                {artwork.attachments.map((attachment, i) => (
                    <Attachment key={i} attachment={attachment} />
                ))}
            </div>

        </Page>
    );
};

export default withRouter(Artwork);
