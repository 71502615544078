import pageAboutArtists from './pages/about-artists.json';
import pageAboutArtwork from './pages/about-artwork.json';
import pageAboutCreativeProcess from './pages/about-creative-process.json';
import pageAboutCrossroads from './pages/about-crossroads.json';
import pageArtAndPlay from './pages/art-and-play.json';
import pageQuestionsAndContact from './pages/questions-and-contact.json';

import artwork1 from './artworks/1.json';
import artwork2 from './artworks/2.json';
import artwork3 from './artworks/3.json';
import artwork4 from './artworks/4.json';
import artwork5 from './artworks/5.json';
import artwork6 from './artworks/6.json';
import artwork7 from './artworks/7.json';
import artwork8 from './artworks/8.json';
import artwork9 from './artworks/9.json';
import artwork10 from './artworks/10.json';
import artwork11 from './artworks/11.json';
import artwork12 from './artworks/12.json';
import artwork13 from './artworks/13.json';
import artwork14 from './artworks/14.json';
import artwork15 from './artworks/15.json';
import artwork16 from './artworks/16.json';
import artwork17 from './artworks/17.json';
import artwork18 from './artworks/18.json';
import artwork19 from './artworks/19.json';
import artwork20 from './artworks/20.json';
import artwork21 from './artworks/21.json';
import artwork22 from './artworks/22.json';
import artwork23 from './artworks/23.json';

const state = {
    pages: [
        pageAboutArtists,
        pageAboutArtwork,
        pageAboutCreativeProcess,
        pageAboutCrossroads,
        pageArtAndPlay
    ],
    artworks: [
        artwork1,
        artwork2,
        artwork3,
        artwork4,
        artwork5,
        artwork6,
        artwork7,
        artwork8,
        artwork9,
        artwork10,
        artwork11,
        artwork12,
        artwork13,
        artwork14,
        artwork15,
        artwork16,
        artwork17,
        artwork18,
        artwork19,
        artwork20,
        artwork21,
        artwork22,
        artwork23
    ],
};

export default state;