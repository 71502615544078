import React from 'react';
import {translate as _} from 'simple-dict-translator';

const Artists = ({className, artists = '', ...props}) => {
    const classNames = ['artists'];
    const isMultipleArtists = artists instanceof Array;
    const content = isMultipleArtists ? artists.join(_('artists_and')) : artists;

    if (className) {
        classNames.push(className);
    }

    return (
        <div
            className={classNames.join(' ')}
            {...props}
        >
            <span className={'artists__label'}>{isMultipleArtists ? _('by_artists') : _('by_artist')}</span> <span className={'artists__artists'}>{content}</span>
        </div>
    );
};

export default Artists;
