import React, {useState} from 'react';
import {translate as _} from 'simple-dict-translator';

const Youtube = ({className, youtubeId = '', title = '', ...props}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const classNames = ['attachment-youtube'];

    if (className) {
        classNames.push(className);
    }

    return (
        <div className={classNames.join(' ')}>
            {!isPlaying && (
                <div
                    role="button"
                    aria-label={_('play_video')}
                    className={'attachment-youtube__cover'}
                    onClick={() => {
                        setIsPlaying(true);
                    }}
                    style={{
                        backgroundImage: `url(https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg)`
                    }}
                >
                    <img
                        className={'attachment-youtube__cover__play-icon'}
                        alt={_('play')}
                        src={require('../../../assets/play-button.svg')}
                    />
                </div>
            )}
            {isPlaying && (
                <iframe
                    title={title}
                    className={'attachment-youtube__player'}
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${youtubeId}?playsinline=1&autoplay=1`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            )}
        </div>
    );
};

export default Youtube;
