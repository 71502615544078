import React from 'react';
import ReactDOM from 'react-dom';
import './sass/main.scss';
import App from './app/App';
import constants from './app/constants';
import localeService from './app/Services/LocaleService';
import * as serviceWorker from './serviceWorker';
import translations from './translations';

localeService.addTranslation(constants.LANGUAGE_ENGLISH, translations[constants.LANGUAGE_ENGLISH]);
localeService.addTranslation(constants.LANGUAGE_SWEDISH, translations[constants.LANGUAGE_SWEDISH]);

localeService.setCurrentLocale(constants.LANGUAGE_DEFAULT);
localeService.setFallbackLocale(constants.LANGUAGE_FALLBACK);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
