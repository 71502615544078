import React from 'react';
import { AnimatedSwitch as RRTAnimatedSwitch } from 'react-router-transition';

const AnimatedSwitch = ({children, ...props}) => {
    return (
        <RRTAnimatedSwitch
            atEnter={{ opacity: 0 }}
            atLeave={{ opacity: 0 }}
            atActive={{ opacity: 1 }}
            className="animated-switch animated-switch--opacity"
            {...props}
        >
            {children}
        </RRTAnimatedSwitch>
    );
};

export default AnimatedSwitch;