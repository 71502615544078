import React from 'react';
import { AnimatedSwitch as RRTAnimatedSwitch } from 'react-router-transition';

function mapStyles(styles) {
    return {
        zIndex: styles.zIndex,
        opacity: styles.opacity,
        top: `${styles.transformTop}%`,
    };
}

const bounceTransition = {
    atEnter: {
        opacity: 1,
        transformTop: -100,
        zIndex: 3
    },
    atLeave: {
        opacity: 0,
        transformTop: 100,
        zIndex: 1
    },
    atActive: {
        opacity: 1,
        transformTop: 0,
        zIndex: 2

    }
};

const AnimatedSwitch = ({children, ...props}) => {
    return (
        <RRTAnimatedSwitch
            className="animated-switch animated-switch--top"
            atEnter={bounceTransition.atEnter}
            atLeave={bounceTransition.atLeave}
            atActive={bounceTransition.atActive}
            mapStyles={mapStyles}
            {...props}
        >
            {children}
        </RRTAnimatedSwitch>
    );
};

export default AnimatedSwitch;