import React from 'react';
import { withRouter } from 'react-router-dom';
import {translate as _} from 'simple-dict-translator';

import Page from './Page';
import LargeTitle from '../Components/LargeTitle';
import ArrowButton from '../Components/Buttons/ArrowButton';
import Html from '../Components/Html';


const Intro = ({match: { params }, history}) => {
    return (
        <Page
            className={'page-intro'}
            showMenu={false}
            showFooter={false}
        >
            <div>
                <LargeTitle className={'page-intro__large-title'} title={_('intro_title')} subtitle={_('intro_subtitle')} />
                <div className={'page-intro__content'}>
                    <Html>{_('intro_content')}</Html>
                </div>
                <div className={'page-intro__actions'}>
                    <ArrowButton 
                        title={_('to_the_artwork')}
                        onClick={() => {
                            history.push('/artwork/start');
                        }}
                    />
                </div>
            </div>
        </Page>
    );
};

export default withRouter(Intro);
