import React from 'react';

import {translate as _} from 'simple-dict-translator';

const ArrowButton = ({className, title, icon, onClick = () => {}, children, ...props}) => {
    const classNames = ['arrow-button'];

    if (className) {
        classNames.push(className);
    }

    return (
        <button
            className={classNames.join(' ')}
            onClick={(ev) => {
                ev.preventDefault();
                onClick();
            }}
            {...props}
        >
            <span className="arrow-button__label heading heading--large heading--normal">{title}</span>
            <img className="arrow-button__arrow" src={require('../../../assets/arrow_right.svg')} alt={_('right_arrow')} />
        </button>
    );
};

export default ArrowButton;
