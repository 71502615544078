import React from 'react';
import constants from '../../constants';

function getIcon(iconType) {
    let icon = '';

    switch (iconType) {
        case constants.ICON_MENU:
            icon = require('../../../assets/menu-button.svg');
            break;
        case constants.ICON_CLOSE:
        default:
            icon = require('../../../assets/close-button.svg');
            break;
    }

    return icon;
}

const Button = ({
    className,
    title,
    icon = false,
    onClick = () => {},
    ...props
}) => {
    const classNames = ['button'];
    let iconImage = null;

    if (className) {
        classNames.push(className);
    }
    
    if (icon) {
        classNames.push('button--icon');
        classNames.push('button--icon--' + icon);
        iconImage = getIcon(icon);
    }

    return (
        <button
            className={classNames.join(' ')}
            onClick={(ev) => {
                ev.preventDefault();
                onClick();
            }}
            {...props}
        >
            {!icon && (
                <span  className={'button__label'}>{title}</span>
            )}
            {!!icon && (
                <img className={'button__icon'} src={iconImage} alt={title} />
            )}
        </button>
    );
};

export default Button;
