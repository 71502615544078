const en_us = {
    'language_swe_shortname': 'SVE',
    'language_en_shortname': 'ENG',
    'menu': 'Menu',
    'to_the_artwork': 'To the artwork',
    'main_title': 'MATRIX',
    'main_subtitle': 'By Ea ten Kate, Jonathan Josefsson and Valeria Montti Colque for the Musem of World Culture',
    'intro_title': 'MATRIX',
    'intro_subtitle': 'By Ea ten Kate, Jonathan Josefsson and Valeria Montti Colque for the Musem of World Culture',
    'intro_content': 'Welcome to Matrix, a groundbreaking work of art on the ground!',
    'banner_message': 'Click on the figures to find out more',
    'artists_and': ' and ',
    'by_artists': 'Artists:',
    'by_artist': 'Artist:',
    'about_figure': 'About the figure',
    'about_objects': 'About the objects',
    'references': 'Reference-id:',
    'play_video': 'Play the video',
    'play': 'Play',
    'right_arrow': 'Right facing arrow',
};

export default en_us;
